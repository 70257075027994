import { html, LitElement, nothing, TemplateResult } from 'lit'
import { property, state } from 'lit/decorators.js'
import { map } from 'lit/directives/map.js'
import { choose } from 'lit/directives/choose.js'
import { classMap } from 'lit/directives/class-map.js'
import playSVG from '../../assets/playSVG'
import { styles } from './styles'
import { isFilledLinkToWebField, isSubNavigationItem } from '../../types/guards'
import type {
  KeyTextField,
  LinkField,
  FilledLinkToWebField,
} from '@prismicio/types'
import type {
  NavigationItemSlice,
  NavigationItemSliceDefaultItem,
  SocialNavigationItemSlice,
  TmlTopbarDocumentData,
  TmlTopbarDocumentDataSlicesSlice,
} from '@repo/prismic-data/prismicio-types'

declare global {
  interface HTMLElementTagNameMap {
    'tml-topbar': TMLTopbar
  }
}

document.addEventListener('DOMContentLoaded', () => {})

// NOTE: This is outside of the web component and just part of JS
// Detect click outside of our web-component
document.addEventListener('click', () =>
  document.querySelector('tml-topbar')?.outsideClick(),
)

export class TMLTopbar extends LitElement {
  static styles = styles

  @state()
  private _openSubNavigationId?: string

  @state()
  private _openInlineNavigationId?: string

  @state()
  private _inlineNavigationItems?: number

  @property()
  language?: string

  // Prismic loader data
  @property({ attribute: false })
  data: Record<string, TmlTopbarDocumentData> | undefined

  loadLanguage(locale: string) {
    this.language = locale
  }

  outsideClick() {
    this._openSubNavigationId = undefined

    setTimeout(() => {
      this._openInlineNavigationId = undefined
      this._inlineNavigationItems = 0
    }, 300)
  }

  private _navigationClickHandler(event: Event) {
    event.stopPropagation()
    document.querySelector('tml-language-picker')?.outsideClick()

    const id = (event.target as HTMLElement)?.id ?? undefined
    this._openSubNavigationId =
      this._openSubNavigationId === id ? undefined : id

    if (this._openSubNavigationId !== id) {
      setTimeout(() => {
        this._openInlineNavigationId = undefined
        this._inlineNavigationItems = 0
      }, 300)
    }
  }

  private _inlineDropdownClickHandler(event: Event, items: number) {
    event.stopPropagation()

    const id = (event.target as HTMLElement)?.id ?? undefined
    this._openInlineNavigationId =
      this._openInlineNavigationId === id ? undefined : id

    this._inlineNavigationItems = items
  }

  private _closeInlineDropdownClickHandler(event: Event) {
    event.stopPropagation()

    this._inlineNavigationItems = 0

    setTimeout(() => {
      this._openInlineNavigationId = undefined
    }, 300)
  }

  renderMobileSubNavigation(
    id: string | null,
    items: NavigationItemSlice['items'],
    openGroupName: string | null,
  ): TemplateResult {
    return html`<div
      class=${classMap({
        'inline-dropdown': true,
        'inline-open': !!(
          this._openInlineNavigationId &&
          id?.includes(this._openInlineNavigationId)
        ),
      })}
    >
      <button @click=${this._closeInlineDropdownClickHandler}>
        <span class="caret"></span><span>${openGroupName}</span>
      </button>
      <ul>
        ${map(items, (item) =>
          this.renderSubNavigationItem(
            item.sub_navigation_item_text,
            item.sub_navigation_item_link,
          ),
        )}
      </ul>
    </div>`
  }

  renderMobileNavigationItem(
    text: KeyTextField,
    link: LinkField,
    items: NavigationItemSlice['items'],
    iconOnly?: boolean,
  ) {
    const isLink = isFilledLinkToWebField(link)
    const hasSubItems = items.length > 0

    return html`<li class=${iconOnly ? 'icon' : nothing}>
      <a
        id=${text}
        class=${iconOnly ? text : nothing}
        href=${isLink ? link.url : nothing}
        target=${isLink ? link.target : nothing}
        role=${hasSubItems ? 'button' : nothing}
        aria-haspopup=${hasSubItems ? 'true' : nothing}
        aria-expanded=${hasSubItems
          ? text === this._openInlineNavigationId
          : nothing}
        @click=${hasSubItems
          ? (event: Event) =>
              this._inlineDropdownClickHandler(event, items.length + 1)
          : nothing}
      >
        <span>${text}</span> ${hasSubItems && !iconOnly
          ? html`<span class="caret"></span>`
          : nothing}
      </a>
      ${hasSubItems
        ? this.renderMobileSubNavigation(text, items, text)
        : nothing}
    </li>`
  }

  renderSubNavigationItem(text: KeyTextField, link: LinkField) {
    const isSubLink = isFilledLinkToWebField(link)

    return html`<li>
      <a
        href=${isSubLink ? link.url : nothing}
        target=${isSubLink ? link.target : nothing}
      >
        ${text}
      </a>
    </li>`
  }

  renderSubNavigation(
    id: string | null,
    items: (NavigationItemSliceDefaultItem | NavigationItemSlice)[],
    socialSlices?: SocialNavigationItemSlice[],
  ): TemplateResult {
    return html`<div
      class=${classMap({
        'sub-navigation': true,
        open: !!(
          this._openSubNavigationId && id?.includes(this._openSubNavigationId)
        ),
        'inline-open': !!this._inlineNavigationItems,
      })}
      style=${this._inlineNavigationItems
        ? `max-height: ${this._inlineNavigationItems * 61}px`
        : 'max-height: initial'}
    >
      <ul
        class=${classMap({
          'inline-open': !!this._inlineNavigationItems,
        })}
      >
        ${map(items, (item) => {
          if (isSubNavigationItem(item)) {
            return this.renderSubNavigationItem(
              item.sub_navigation_item_text,
              item.sub_navigation_item_link,
            )
          } else {
            return this.renderMobileNavigationItem(
              item.primary.navigation_item_text,
              item.primary.navigation_item_link,
              item.items,
            )
          }
        })}
        ${socialSlices
          ? html`<li>
              <ul class="mobile-socials">
                ${map(socialSlices, (slice) =>
                  this.renderMobileNavigationItem(
                    slice.primary.platform_icon,
                    slice.primary.platform_link,
                    slice.items,
                    true,
                  ),
                )}
              </ul>
            </li>`
          : nothing}
      </ul>
    </div>`
  }

  renderMainNavigationItem(
    text: KeyTextField,
    link: LinkField,
    items: (NavigationItemSliceDefaultItem | NavigationItemSlice)[],
    iconOnly?: boolean,
    socialSlices?: SocialNavigationItemSlice[],
  ) {
    const isLink = isFilledLinkToWebField(link)
    const hasSubItems = items.length > 0

    return html`<li class=${iconOnly ? 'icon' : nothing}>
      <a
        id=${text}
        class=${iconOnly ? text : nothing}
        href=${isLink ? link.url : nothing}
        target=${isLink ? link.target : nothing}
        role=${hasSubItems ? 'button' : nothing}
        aria-haspopup=${hasSubItems ? 'true' : nothing}
        aria-expanded=${hasSubItems
          ? text === this._openSubNavigationId
          : nothing}
        @click=${hasSubItems ? this._navigationClickHandler : nothing}
      >
        <span>${text}</span> ${hasSubItems
          ? html`<span class="caret"></span>`
          : nothing}
      </a>
      ${hasSubItems
        ? this.renderSubNavigation(text, items, socialSlices)
        : nothing}
    </li>`
  }

  renderMyAccount(
    my_account_link_text: KeyTextField,
    my_account_link: KeyTextField,
    my_account_callback_link: KeyTextField,
    userIntegration: boolean,
  ) {
    return html`<a
      class="my-account"
      href=${userIntegration
        ? `${my_account_callback_link}${window.location}`
        : my_account_link}
    >
      ${my_account_link_text}
    </a>`
  }

  render() {
    const data = this.data?.[this.language || 'en-us']
    if (!data) return nothing

    return html`<nav>
      <ul class="tml-topbar--left">
        ${map(data.slices, (slice) =>
          choose(slice.slice_type, [
            [
              'navigation_item',
              () => {
                const { navigation_item_text, navigation_item_link } = (
                  slice as NavigationItemSlice
                ).primary

                return this.renderMainNavigationItem(
                  navigation_item_text,
                  navigation_item_link,
                  slice.items,
                )
              },
            ],
            [
              'social_navigation_item',
              () => {
                const { platform_icon, platform_link } = (
                  slice as SocialNavigationItemSlice
                ).primary

                return this.renderMainNavigationItem(
                  platform_icon,
                  platform_link,
                  slice.items,
                  true,
                )
              },
            ],
          ]),
        )}
      </ul>
      <ul class="tml-topbar--left mobile">
        <li class="icon">
          <a
            class="tml-logo"
            href=${(
              (data.slices[0] as NavigationItemSlice).primary
                .navigation_item_link as FilledLinkToWebField
            ).url}
            target=${(
              (data.slices[0] as NavigationItemSlice).primary
                .navigation_item_link as FilledLinkToWebField
            ).target}
          >
            <span
              >${(data.slices[0] as NavigationItemSlice).primary
                .navigation_item_text}</span
            >
          </a>
        </li>
        ${this.renderMainNavigationItem(
          (data.slices[0] as NavigationItemSlice).primary.navigation_item_text,
          { link_type: 'Any' },
          data.slices.filter(
            (slice: TmlTopbarDocumentDataSlicesSlice, index) =>
              index !== 0 && slice.slice_type === 'navigation_item',
          ) as NavigationItemSlice[],
          false,
          data.slices.filter(
            (slice: TmlTopbarDocumentDataSlicesSlice) =>
              slice.slice_type === 'social_navigation_item',
          ) as SocialNavigationItemSlice[],
        )}
      </ul>
      <ul class="tml-topbar--right">
        ${data.radio_player_link
          ? html`<a class="radio-button" href=${data.radio_player_link}>
              ${playSVG} Radio
            </a>`
          : nothing}
        ${data.radio_player_link_mobile
          ? html`<a
              class="radio-button mobile"
              href=${data.radio_player_link_mobile}
            >
              ${playSVG} Radio
            </a>`
          : nothing}
        <slot name="language-picker"></slot>
        <li>
          ${this.renderMyAccount(
            data.my_account_link_text,
            data.my_account_link,
            data.my_account_callback_link,
            data.user_integration,
          )}
        </li>
      </ul>
    </nav>`
  }
}

// Define only if not already exists
if (!window.customElements.get('tml-topbar')) {
  window.customElements.define('tml-topbar', TMLTopbar)
}
