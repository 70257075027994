import { css } from 'lit'

export const styles = css`
  :host {
    z-index: 9960;
    position: fixed;
    width: 100%;
    height: var(--tml-topbar-height, 37px);
  }

  nav {
    display: flex;
    color: var(--tml-topbar-color, rgba(255, 255, 255, 0.7));
    background-color: var(--tml-topbar-background-color, black);
  }

  a,
  span,
  button {
    font-size: 12px;
    font-weight: 700;
    font-family: europa, sans-serif;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  button {
    color: var(--tml-topbar-color, rgba(255, 255, 255, 0.7));
    border: 0;
    background: none;
    cursor: pointer;
  }

  button:hover {
    color: var(--tml-topbar-color-hover, rgba(255, 255, 255, 0.9));
  }

  ul {
    flex: 0 1 auto;
    display: flex;
    margin: 0;
    padding: 0;
  }

  span {
    pointer-events: none;
  }

  .tml-topbar--left:not(.mobile) > li:not(:first-child):not(.icon) {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }

  .tml-topbar--left > li:first-child > a {
    display: inline-block;
    padding-left: 42px;
  }

  .tml-topbar--left > li:nth-child(2) > a span:not(.caret) {
    display: none;
  }

  @media only screen and (min-width: 500px) {
    .tml-topbar--left > li:nth-child(2) > a span:not(.caret) {
      display: inline-block;
    }
  }

  @media only screen and (min-width: 75em) {
    .tml-topbar--left > li:first-child > a {
      padding-left: 28px;
      text-indent: -9999px;
    }
  }

  @media only screen and (min-width: 1370px) {
    .tml-topbar--left > li:first-child > a {
      padding-left: 42px;
      text-indent: 0;
    }
  }

  .tml-topbar--left > li:first-child > a:before {
    content: '';
    position: absolute;
    display: block;
    width: 18px;
    height: 21px;
    top: 8px;
    left: 12px;
    margin: auto;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' fill-rule='evenodd' viewBox='0 0 17.4 20'%3E %3Cpath d='M8.7 6.1A1.2 1.2 0 1 1 9.9 5a1.2 1.2 0 0 1-1.2 1.1zM11.2 5a3.1 3.1 0 0 0-2.5-1.4A3.1 3.1 0 0 0 6.1 5a4 4 0 0 0 2.6 1.3A4 4 0 0 0 11.2 5z'/%3E %3Cpath d='M8.4 4.3a.4.4 0 0 0 0 .8.4.4 0 0 0 0-.8'/%3E %3Cpath d='M.6 9.3a4.7 4.7 0 0 0 .6.8v.1a7.5 7.5 0 1 0 15 0 5.2 5.2 0 0 0 .6-.9 9 9 0 0 1 0 1.3c0 6.4-8.1 9-8.1 9s-8.2-2.4-8.2-9a9 9 0 0 1 .1-1.3zm6.2-2.2c1.3.9 1.7 2 1.7 4.3 0 5-2 5.5-2 5.5s-3.2-1-3.2-3.2c0-1.4 1.9-1.8 1.9-1.8s-8.7-2.4-2.7-8.7c.1 1.5 2.9 3 4.3 4zm6.8-2.6a7.4 7.4 0 0 0-9.8 0 5.8 5.8 0 0 1-.5-.5A3 3 0 0 0 4 2.3a2.3 2.3 0 0 0 2 .1h.1a5.3 5.3 0 0 0 2.6-2 5.2 5.2 0 0 0 2.5 2h.2a2.3 2.3 0 0 0 2 0 3 3 0 0 0 .7 1.6 4.5 4.5 0 0 1-.5.5zm-5 4a3.6 3.6 0 0 0-1.6-2C5.7 5.4 5.5 5 5.5 5a3.5 3.5 0 0 1 3.2-1.9A3.5 3.5 0 0 1 11.9 5s-.2.6-1.5 1.5a3.6 3.6 0 0 0-1.7 2zm5.5 5.1c0 2.3-3.3 3.3-3.3 3.3S9 16.3 9 11.4C9 9.1 9.3 8 10.6 7s4.2-2.5 4.3-4c6 6.3-2.7 8.8-2.7 8.8s2 .4 2 1.7zm3.2-5.4c.4-1.6-.3-3.4-2.1-5.3a.4.4 0 0 0-.3-.1.3.3 0 0 0-.1 0 .4.4 0 0 0-.3.3 1.3 1.3 0 0 1-.2.6 2.4 2.4 0 0 1-.5-1.4.4.4 0 0 0-.2-.3.3.3 0 0 0-.2 0 .5.5 0 0 0-.2 0 1.2 1.2 0 0 1-.7.2 3.1 3.1 0 0 1-1-.1l-.2-.1A4.9 4.9 0 0 1 9 .2a.4.4 0 0 0-.6 0A4.9 4.9 0 0 1 6 2a3.4 3.4 0 0 1-1 .2 1.2 1.2 0 0 1-.8-.2.5.5 0 0 0-.2 0 .5.5 0 0 0-.2 0 .4.4 0 0 0-.2.3 2.4 2.4 0 0 1-.5 1.3 1.2 1.2 0 0 1-.2-.5.4.4 0 0 0-.2-.3.3.3 0 0 0-.2 0 .4.4 0 0 0-.2 0C.3 4.9-.3 6.7 0 8.3a3.8 3.8 0 0 0 .3.8.4.4 0 0 0-.2.3 10 10 0 0 0 0 1.3c0 2.9 1.4 5.4 4.2 7.3a16.4 16.4 0 0 0 4.3 2h.2a16.9 16.9 0 0 0 4.2-2.2c2.8-2 4.3-4.4 4.3-7.2a10 10 0 0 0-.1-1.3.4.4 0 0 0-.2-.3 3.8 3.8 0 0 0 .3-.8z'/%3E %3C/svg%3E");
    background-repeat: no-repeat;
  }

  .tml-topbar--right {
    margin-left: auto;
  }

  .sub-navigation {
    z-index: 9960;
    position: absolute;
    opacity: 0;
    background: #fff;
    box-shadow: 0 15px 70px 0 rgb(0 0 0 / 50%);
    transition: opacity 0.3s ease;
    pointer-events: none;
    overflow-x: clip;
  }

  .sub-navigation.open {
    opacity: 1;
    pointer-events: auto;
  }

  .sub-navigation > ul {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    transition: transform 0.3s ease;
  }

  .sub-navigation > ul.inline-open {
    transform: translate3d(-100%, 0, 0);
  }

  .sub-navigation li {
    height: 60px;
  }

  .sub-navigation > li:last-child a {
    border-bottom: 0;
  }

  .sub-navigation a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    text-transform: uppercase;
    padding: 0 30px 0 20px;
    line-height: 1.8;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: pre-wrap;
  }

  .sub-navigation a,
  .sub-navigation a:active,
  .sub-navigation a:visited {
    color: var(--tml-topbar-color, rgba(0, 0, 0, 0.6));
  }

  .sub-navigation a:hover {
    color: var(--tml-topbar-color-hover, rgba(0, 0, 0, 1));
  }

  .caret {
    opacity: 0.7;
    display: inline-block;
    width: 12px;
    height: 12px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='666 518 11 11'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M671.75 525.76l3.28-3.28.57-.57 1.13 1.13-.56.57-3.85 3.85-.57.56-.28-.28-.29-.28-3.85-3.86-.56-.56 1.13-1.13.56.56 3.29 3.29z'/%3E%3C/svg%3E");
    pointer-events: none;
  }

  li {
    list-style: none;
    white-space: nowrap;
  }

  .icon {
    width: 28px;
    height: var(--tml-topbar-height, 37px);
  }

  .icon > a {
    border: 0;
    opacity: 0.7;
    text-indent: -9999px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: auto 17px;
  }

  a {
    display: block;
    padding: 0 14px;
    text-transform: uppercase;
    text-decoration: none;
    line-height: var(--tml-topbar-height, 37px);
    cursor: pointer;
  }

  a,
  a:active,
  a:visited {
    color: var(--tml-topbar-color, rgba(255, 255, 255, 0.7));
  }

  a:hover {
    opacity: 1;
    color: var(--tml-topbar-color-hover, rgba(255, 255, 255, 0.9));
  }

  a:hover .caret {
    opacity: 0.9;
  }

  .facebook {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='54 0 12 24'%3E %3Cpath fill='%23fff' d='M65.4 7.7h-3.7V5.3c0-1 .6-1.2 1.1-1.2h2.6v-4L61.8 0c-4.1 0-5 3.1-5 5v2.7h-2.3v4.2h2.3v11.8h4.9V11.9H65l.4-4.2z'/%3E %3C/svg%3E");
  }

  .instagram {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='27 0 24 24'%3E %3Cg fill='%23fff' transform='translate(27)'%3E %3Cpath d='M11.83 5.98a6.03 6.03 0 0 0 0 12.04 6.03 6.03 0 0 0 0-12.04zm0 9.88c-2.11 0-3.85-1.74-3.85-3.86s1.74-3.86 3.85-3.86c2.11 0 3.85 1.74 3.85 3.86s-1.74 3.86-3.85 3.86z'/%3E %3Cellipse cx='18.07' cy='5.83' rx='1.36' ry='1.36'/%3E %3Cpath d='M21.6 2.3A6.68 6.68 0 0 0 16.65.39H7C2.91.38.2 3.1.2 7.2v9.65c0 2.02.65 3.76 1.97 5.03a6.85 6.85 0 0 0 4.88 1.84h9.58c2.02 0 3.7-.66 4.93-1.84a6.75 6.75 0 0 0 1.92-4.99V7.2c0-1.98-.65-3.67-1.87-4.9zm-.29 14.6c0 1.45-.42 2.63-1.26 3.43a4.85 4.85 0 0 1-3.43 1.22H7.04c-1.4 0-2.58-.42-3.43-1.22a4.74 4.74 0 0 1-1.26-3.49V7.2A4.7 4.7 0 0 1 3.6 3.76c.8-.8 2.02-1.22 3.43-1.22h9.67c1.41 0 2.58.43 3.43 1.27.8.85 1.27 2.03 1.27 3.4v9.69h-.1z'/%3E %3C/g%3E %3C/svg%3E");
  }

  .twitter {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 1200 1227' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M714.163 519.284 1160.89 0h-105.86L667.137 450.887 357.328 0H0l468.492 681.821L0 1226.37h105.866l409.625-476.152 327.181 476.152H1200L714.137 519.284h.026ZM569.165 687.828l-47.468-67.894-377.686-540.24h162.604l304.797 435.991 47.468 67.894 396.2 566.721H892.476L569.165 687.854v-.026Z' fill='%23fff'/%3E%3C/svg%3E");
    background-position: 50% calc(50% + 1px);
    background-size: 18px !important;
  }

  .youtube {
    margin-left: 4px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 192 192'%3E %3Cpath d='M180.3 53.4c-2-7.6-8-13.6-15.6-15.7C151 34 96 34 96 34s-55 0-68.8 3.7c-7.6 2-13.5 8-15.6 15.7C8 67.2 8 96 8 96s0 28.8 3.7 42.6c2 7.6 8 13.6 15.6 15.7C41 158 96 158 96 158s55 0 68.8-3.7c7.6-2 13.5-8 15.6-15.7C184 124.8 184 96 184 96s0-28.8-3.7-42.6zM78 122.2V69.8L124 96l-46 26.2z' fill='%23fff'/%3E %3C/svg%3E ");
    background-size: 24px !important;
    background-position-y: calc(50% + 0.5px);
  }

  .tiktok {
    margin-left: 4px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1529.6 1770.6' height='24px' fill='%23FFFFFF' stroke='%23FFFFFF'%3E%3Cpath stroke-miterlimit='10' stroke-width='100' d='M1479.6 728c-150.4 0-332-75.8-414.5-201.7V1213a507.6 507.6 0 1 1-507.5-507.5c10.5 0 21 1 31.3 1.6v250.1c-10.4-1.3-20.6-3.1-31.3-3.1a259 259 0 0 0 0 518c143 0 269.4-112.7 269.4-255.8L829.5 50h239.3a445.7 445.7 0 0 0 410.8 398Z'%3E%3C/path%3E%3C/svg%3E");
    background-size: 16px !important;
    background-position-y: calc(50% + 0.5px);
  }

  .discord {
    margin-left: 4px;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 -2.672 24 24' xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath fill='%23fff' d='M20.33 1.556A19.547 19.547 0 0 0 15.379 0c-.213.386-.462.904-.634 1.317a18.154 18.154 0 0 0-5.487 0A14.183 14.183 0 0 0 8.616 0a19.482 19.482 0 0 0-4.955 1.56C.527 6.295-.323 10.913.102 15.465a19.787 19.787 0 0 0 6.073 3.112 15.103 15.103 0 0 0 1.3-2.143 12.789 12.789 0 0 1-2.048-.997 10.185 10.185 0 0 0 .502-.397c3.949 1.847 8.24 1.847 12.142 0a12.343 12.343 0 0 0 .502.397 12.757 12.757 0 0 1-2.052.999c.376.752.81 1.469 1.301 2.142a19.753 19.753 0 0 0 6.076-3.114c.498-5.277-.851-9.852-3.568-13.909ZM8.013 12.665c-1.185 0-2.158-1.107-2.158-2.454s.951-2.456 2.158-2.456c1.206 0 2.178 1.107 2.158 2.456.002 1.348-.951 2.454-2.158 2.454Zm7.974 0c-1.185 0-2.158-1.107-2.158-2.454s.951-2.456 2.158-2.456c1.206 0 2.178 1.107 2.158 2.456 0 1.348-.951 2.454-2.158 2.454Z'/%3E%3C/svg%3E");
    background-size: 20px !important;
    background-position-y: calc(50% + 0.5px);
  }

  .tml-topbar--left {
    display: none;
  }

  @media only screen and (min-width: 75em) {
    .tml-topbar--left {
      display: flex;
    }
  }

  .tml-topbar--left.mobile {
    display: flex;
  }

  @media only screen and (min-width: 75em) {
    .tml-topbar--left.mobile {
      display: none;
    }
  }

  .tml-topbar--left.mobile > li:nth-child(2) > a {
    padding-left: 42px;
  }

  .tml-topbar--left.mobile .sub-navigation {
    width: 100%;
  }

  .tml-topbar--left.mobile .sub-navigation a {
    padding: 0 20px;
  }

  .tml-topbar--left.mobile .sub-navigation .caret {
    transition: transform 0.3s ease;
    transform: rotate(-90deg);
    filter: invert(100%) sepia(17%) saturate(0%) hue-rotate(203deg)
      brightness(104%) contrast(101%);
  }

  .tml-topbar--left.mobile .inline-dropdown {
    position: absolute;
    width: calc(100% - 20px);
    top: 0;
    left: 100%;
    padding: 0 10px;
    background: #fff;
    opacity: 0;
    pointer-events: none;
  }

  .tml-topbar--left.mobile .inline-dropdown.inline-open {
    opacity: 1;
    pointer-events: auto;
  }

  .tml-topbar--left.mobile .inline-dropdown button {
    background: none;
    outline: 0;

    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    padding: 0 30px 0 20px;
    text-transform: uppercase;
    height: 60px;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    cursor: pointer;
    pointer-events: auto;
  }

  .inline-dropdown button,
  .inline-dropdown button:active,
  .inline-dropdown button:visited {
    color: var(--tml-topbar-color, rgba(0, 0, 0, 0.6));
  }

  .tml-topbar--left.mobile .inline-dropdown button:hover span {
    color: var(--tml-topbar-color-hover, rgba(0, 0, 0, 1));
  }

  .tml-topbar--left.mobile .inline-dropdown ul {
    display: flex;
    flex-direction: column;
  }

  .tml-topbar--left.mobile .inline-dropdown ul li:last-child a {
    border: 0;
  }

  .tml-topbar--left.mobile .inline-dropdown button .caret {
    transition: transform 0.3s ease;
    transform: rotate(90deg);
    filter: invert(100%) sepia(17%) saturate(0%) hue-rotate(203deg)
      brightness(104%) contrast(101%);
  }

  .mobile-socials {
    display: flex;
    justify-content: center;
    height: 100%;
  }

  .mobile-socials .icon {
    width: 50px;
    height: 100%;
  }

  .mobile-socials .icon > a {
    filter: invert(100%) sepia(17%) saturate(0%) hue-rotate(203deg)
      brightness(104%) contrast(101%);
  }

  .tml-topbar--left.mobile > li.icon {
    position: absolute;
    top: 0;
    left: 0;
  }

  .tml-topbar--left.mobile > li.icon .tml-logo {
    width: 100%;
    padding: 0;
  }

  @media (min-width: 992px) {
    :host(.homepage) nav {
      position: relative;
      width: calc(100% - 90px);
      left: 90px;
      backdrop-filter: blur(5px);
      background-color: transparent;
      background-image: linear-gradient(
        179deg,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.5)
      );
    }

    :host(.homepage)
      .tml-topbar--left:not(.mobile)
      > li:not(:first-child):not(.icon) {
      border: 0;
    }

    :host(.homepage) .tml-topbar--left.mobile > li:nth-child(2) a {
      padding-left: 20px;
    }

    :host(.homepage) .tml-topbar--left.mobile > li:nth-child(2) a::before {
      content: none;
    }

    :host(.homepage) .tml-topbar--left > li:first-child {
      display: none;
    }
  }

  .radio-button {
    display: none;
    align-items: center;
    gap: 4px;
    padding: 0px 14px;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
  }

  .radio-button svg path {
    fill: var(--tml-topbar-color, rgba(255, 255, 255, 0.7));
  }

  .radio-button:hover svg path {
    fill: var(--tml-topbar-color-hover, rgba(255, 255, 255, 0.9));
  }

  .radio-button.mobile {
    display: flex;
  }

  @media (min-width: 768px) {
    .radio-button.mobile {
      display: none;
    }

    .radio-button:not(.mobile) {
      display: flex;
    }
  }
`
